

import { Vue, Component, Ref } from 'vue-property-decorator'
import { Product } from '../../types/supplier'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '../../types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { UploadFile }
})
export default class SupplierProductAdd extends Vue {
  @Ref('form') readonly form!: ElForm

  productForm: Product = {
    productId: '',
    productName: '',
    productType: '',
    productDesc: '',
    productPrice: '',
    supplierId: '',
    resourceList: []
  }

  rules = {
    productName: [{ required: true, message: '请输入产品名称', trigger: 'blur' }],
    productType: [{ required: true, message: '请输入产品型号', trigger: 'blur' }],
    productDesc: [{ required: true, message: '请输入产品描述', trigger: 'blur' }],
    productPrice: [{ required: true, message: '请输入产品报价', trigger: 'blur' }],
    resourceList: [{ required: true, type: 'array', message: '请上传产品资料', trigger: 'change' }]
  }

  created () {
    this.productForm.supplierId = this.$route.params.id
    this.productForm.productId = this.$route.query.productId as string
    if (this.productForm.productId) {
      this.loadData()
    }
  }

  loadData () {
    this.$axios.get<Product>(this.$apis.supplier.getProductById, {
      productId: this.productForm.productId
    }).then(res => {
      this.productForm = res;
      (this.$refs.uploadFile as UploadFile).getUpdateFileInfo(res.resourceList) // 文件列表回显时调用
    })
  }

  submit () {
    this.form.validate(valid => {
      if (valid) {
        this.$axios.post(this.productForm.productId ? this.$apis.supplier.updateProduct : this.$apis.supplier.insertProduct, this.productForm).then(() => {
          this.$router.back()
        })
      }
    })
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    console.log(file)
    this.productForm.resourceList.push(file)
  }

  // 文件删除
  onRemove (key: string) {
    const num = this.productForm.resourceList.findIndex((item: FileInfo) => {
      return item.fileUrl === key
    })
    this.productForm.resourceList.splice(num, 1)
  }
}
